import { t } from 'ttag';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ListViewContainer from '../../common/ListViewContainer';
import { formatDateTime } from '../../../utils';
import { getCarBookings } from '../../Home/actions';
import useShallowEqualSelector from '../../../hooks/useShallowEqualSelector';
import './main.scss';

const ListBookings = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const bookings = useShallowEqualSelector(state => state.Home.bookings);
    const { id } = params;

    useEffect(() => {
        dispatch(getCarBookings(id));
    }, []);

    return (
        <ListViewContainer
            id='booking'
            title={t`BOOKING_TITLE`}
            noItemsText={t`NO_BOOKINGS_DONE`}
            listTitle={t`MY_BOOKINGS`}
            history={bookings.map(({ since, until, driver }, i) => (
                <div className='card'>
                    <div className='card-content' key={i}>
                        <span className='card-label'>
                            {driver?.username || t`N/A`}
                        </span>
                        <span className='card-secondary'>
                            <span>
                                {t`START_TIME`}: {formatDateTime(since).full}
                            </span>
                            <span>
                                {t`END_TIME`}: {formatDateTime(until).full}
                            </span>
                        </span>
                    </div>
                </div>

            ))}
        />
    );
};

export default ListBookings;
