import { t } from 'ttag';

export const SORTS = () => [
    {
        value: 'name',
        label: t`CITY_NAME`,
        fn: (a, b) => `${a.name}`.localeCompare(b.name, undefined, { numeric: true }),
    },
    {
        value: 'since_now',
        label: t`TIME_PERIOD`,
        fn: (a, b) => (a.mileage?.since_now || a.since_now) - (b.mileage?.since_now || b.since_now),
    },
    { value: 'ongoing', label: t`STATUS`, fn: (a, b) => !!a.mileage - !!b.mileage },
];

export const getCoords = onGetCoords => {
    navigator.geolocation.getCurrentPosition(pos => {
        const { latitude, longitude } = pos.coords;

        onGetCoords({ latitude, longitude });
    });
};

export const validate = (ongoing, wialon, formData, setErrors) => {
    const errors = {};

    const { mileage, location, reason } = formData;

    if(!wialon) {
        if(!mileage.trim()) errors.mileage = t`REQUIRED`;
        else if(Number.isNaN(Number(mileage)) || Number.isNaN(parseFloat(mileage))) errors.mileage = t`INVALID_NUMBER`;
        else if(mileage < 0) errors.mileage = t`POSITIVE_NUMBER_REQUIRED`;

        if(!location.trim()) errors.location = t`REQUIRED`;
    }

    if(ongoing && !reason) errors.reason = t`REQUIRED`;

    setErrors(errors);

    return !Object.values(errors).filter(v => v).length;
};

export const getNewDisabledReducer = (objDisabled, idOrg, idComp, state) => {
    const organization = { ...objDisabled.organization };
    const company = { ...objDisabled.company };

    if(idOrg) Object.assign(organization, { [idOrg]: state });
    else Object.assign(company, { [idComp]: state });

    return { organization, company };
};

export const getIsDisabled = (objDisabled, idOrg, idComp) => {
    if(!idOrg && !idComp) return true;
    if(idOrg) return objDisabled.organization[idOrg];
    if(idComp) return objDisabled.company[idComp];
};
