import {
    REQUEST,
    FAILURE,
    GET_JOURNEY_CUSTOMERS,
    START_JOURNEY,
    END_JOURNEY,
    INITIATE_JOURNEY_REQUEST,
    INITIATE_JOURNEY_FAILURE,
} from 'components/Journey/actions.js';

import { getNewDisabledReducer } from './utils.js';

const initialState = {
    customers: [],
    disabled: {
        organization: {},
        company: {},
    },
};

function journey(state = initialState, action) {
    const { type, response, error, payload } = action;

    switch(type) {
        case REQUEST: { return state; }
        case INITIATE_JOURNEY_REQUEST: {
            const { organization_id, company_id } = action;
            const disabled = getNewDisabledReducer(state.disabled, organization_id, company_id, true);
            return { ...state, disabled };
        }
        case INITIATE_JOURNEY_FAILURE: {
            const { organization_id, company_id } = action;
            const disabled = getNewDisabledReducer(state.disabled, organization_id, company_id, false);
            return { ...state, disabled };
        }
        case FAILURE: {
            return { ...state, error };
        }
        case GET_JOURNEY_CUSTOMERS: {
            return { ...state, customers: response };
        }
        case START_JOURNEY: {
            const { organization_id, company_id } = payload;
            const disabled = getNewDisabledReducer(state.disabled, organization_id, company_id, false);

            const customers = state.customers.map(customer => (
                ((customer.organization_id === organization_id) && (customer.company_id === company_id))
                    ? ({ ...customer, mileage: payload, since_now: 0 })
                    : customer
            ));

            return { ...state, customers, disabled };
        }
        case END_JOURNEY: {
            const { organization_id, company_id } = payload;

            const customers = state.customers.map(customer => (
                ((customer.organization_id === organization_id) && (customer.company_id === company_id))
                    ? ({ ...customer, mileage: null, since_now: null })
                    : customer
            ));

            return { ...state, customers };
        }
        default: { return state; }
    }
}

export default journey;
