import { t } from 'ttag';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { startJourney } from 'components/Journey/actions.js';
import { getIsDisabled } from 'components/Journey/utils.js';
import './main.scss';

const ListItem = ({ customer, setCustomer, forceName, onToggle, deltas }) => {
    const dispatch = useDispatch();
    const wialon = useSelector(state => state.SelectVehicle.currentVehicle.wialon);
    const car_id = useSelector(state => state.SelectVehicle.currentVehicle.id);
    const disabled = useSelector(state => state.Journey.disabled);

    const { mileage, name, company_id, organization_id, since_now } = customer || {};
    const renderedName = forceName || name;
    const isButtonDisabled = getIsDisabled(disabled, organization_id, company_id);

    const handleClick = () => {
        if(wialon && !mileage) {
            const payload = {
                car_id,
                ...(company_id && { company_id }),
                ...(organization_id && { organization_id }),
                since: moment().toISOString(),
            };

            dispatch(startJourney(payload, organization_id, company_id));
            onToggle(organization_id, company_id, true);
        } else setCustomer(customer);
    };

    const delta = deltas[`${organization_id || 0}.${company_id || 0}`] || 0;

    return (
        <li className={`journey-list-item ${mileage ? 'in-progress' : ''}`}>
            <div>
                <span className='customer-name' title={renderedName}>
                    {renderedName}
                </span>
                <span className='journey-duration'>
                    {moment.utc(Math.max((since_now || 0) + delta, 0)).format('HH:mm:ss')}
                </span>
            </div>
            <button
                type='button'
                className={`journey-action-button ${isButtonDisabled ? 'disabled' : ''}`}
                onClick={handleClick}
                disabled={isButtonDisabled}>
                {mileage ? t`END` : t`START`}
            </button>
        </li>
    );
};

export default ListItem;
